import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStore } from "../../stores";
import { dispatch } from "../../redux/store";
import { checkLogin } from "../../redux/slices/auth";

const Container = () => {
  const { logged } = useSelector((state) => state.auth);
  const loading = useStore.useLoading();
  const setPrevPath = useStore.useSetPrevPath();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("location.pathname]", location.pathname);
    dispatch(checkLogin(navigate));
    // if (!user.user_type) {
    //   if (token.length === 0)
    //     if (product_id) navigate(`login/${product_id}`);
    //     else navigate("login");
    //   return;
    // }
    // if (user.user_type === 3) {
    //   // RETAIL
    //   if (location.pathname.match("/login")) {
    //     if (token.length === 0) navigate(`login/${product_id}`);
    //     else navigate(prevPath);
    //     return;
    //   }
    //   if (location.pathname.match("/broker")) navigate(prevPath);
    //   else navigate(location.pathname);
    // } else {
    //   // BROKER, TULUULUGCH
    //   if (location.pathname.match("/login")) {
    //     if (token.length === 0) navigate("login");
    //     else navigate(prevPath);
    //     return;
    //   }
    //   if (location.pathname.match("/broker")) navigate(location.pathname);
    //   else navigate(prevPath);
    // }
  }, [logged]);

  useEffect(() => {
    if (location.pathname.match("insurance-process")) return;
    setPrevPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className="text-primary h-full bg-primary">
      <div
        className={twMerge([
          "flex justify-center items-center z-50 fixed top-0 bottom-0 right-0 left-0 bg-white opacity-50",
          !loading && "hidden ",
        ])}
      >
        <div className="w-8 h-8 p-[1px] border rounded-full bg-gradient-to-r from-primary to-stroke animate-[spin_.8s_linear_0s_infinite]">
          <div className="w-full h-full rounded-full bg-white"></div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      // transition: Bounce,
      />
      <Outlet />
    </div>
  );
};

export default Container;
