import { Transition } from "@headlessui/react";
import { ReactComponent as Success } from "../../assets/icon/fi-sr-check-circle-filled.svg";
import { ReactComponent as Info } from "../../assets/icon/fi-rr-interrogation.svg";
import { ReactComponent as Warning } from "../../assets/icon/fi-rr-exclamation.svg";
import { ReactComponent as Error } from "../../assets/icon/fi-rr-cross-circle.svg";
import { ReactComponent as Cross } from "../../assets/icon/cross.svg";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import IconButton from "../IconButton";
import Typography from "../Typography";
import Modal from "../Modal";

const AlertModal = (props) => {
  const {
    isOpen,
    onClose = () => {},
    type,
    withIcon = false,
    title,
    closeButton = false,
    message,
    footer,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      placement="center"
      panelClassName="bg-white"
    >
      {closeButton && (
        <IconButton
          variant="outlinePrimary"
          onClick={onClose}
          className="border-0 absolute top-4 right-4"
        >
          <Cross fill="#0A213A" className="w-5 h-5" />
        </IconButton>
      )}
      <div className="p-5 text-center self-center">
        {withIcon && (
          <div className="flex justify-center">
            <div
              className={twMerge([
                "rounded-full p-4",
                type === "success" && "bg-success-soft",
                type === "warning" && "bg-warning-soft",
                type === "info" && "bg-info-soft",
                type === "error" && "bg-error-soft",
              ])}
            >
              {type === "success" && <Success className="w-6 h-6 mx-auto" />}
              {type === "warning" && <Warning className="w-6 h-6 mx-auto" />}
              {type === "info" && <Info className="w-6 h-6 mx-auto" />}
              {type === "error" && <Error className="w-6 h-6 mx-auto" />}
            </div>
          </div>
        )}
        <Typography
          variant="Headline"
          size="sm"
          className={twMerge(["mt-5", !message && "mb-12"])}
        >
          {title}
        </Typography>
        <div className="mt-2 text-gray">{message}</div>
      </div>
      {footer && (
        <div className="pt-4 px-8 flex justify-center align-center border-t border-stroke -mx-8">
          {footer}
        </div>
      )}
    </Modal>
  );
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  closeButton: PropTypes.bool,
  message: PropTypes.string,
  footer: PropTypes.element,
};

export default AlertModal;
