import { forwardRef, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { isNullOrUndefined } from "../../utils/helper";

const Input = forwardRef((props, ref) => {
  const {
    id,
    value,
    width,
    label,
    className,
    disabled,
    focus = false,
    onBlur = () => {},
    size = "lg",
    maxLength,
    minLength,
  } = props;
  const [isFocus, setIsFocus] = useState(focus);

  useEffect(() => {
    if (
      isNullOrUndefined(document.getElementById(id)?.value) ||
      document.getElementById(id)?.value === ""
    )
      return;
    setIsFocus(true);
  }, []);

  const onFocus = () => {
    setIsFocus(true);
  };
  const handleBlur = (e) => {
    if (document.getElementById(id)?.value === "") setIsFocus(false);
    if (!isNullOrUndefined(document.getElementById(id)?.value)) return;
    setIsFocus(false);
    onBlur(e);
  };

  return (
    <div
      className={twMerge([
        "relative grid gap-2",
        width,
        // !value && 'flex items-center'
      ])}
    >
      <label
        className={twMerge(["text-gray text-sm", !label && "hidden"])}
        // className={twMerge([
        //   "absolute top-0 left-0 z-10 translate-x-4 translate-y-4 text-sm text-font bg-transparent px-2 transition-all duration-300 pointer-events-none",
        //   disabled && "text-stroke opacity-30",
        //   (isFocus || value) && "-translate-y-2 text-xs bg-white",
        // ])}
        htmlFor={id}
      >
        {label}
      </label>
      <input
        {...props}
        ref={ref}
        id={id}
        value={value}
        className={twMerge([
          "transition duration-200 ease-in-out w-full text-sm text-font border-stroke border px-4 py-4 shadow-sm rounded-lg font-medium tracking-wide outline-none spin-button-none",
          "placeholder:autofill:bg-black", // Placeholder autofill:bg-white
          "active:border-gray", // Active
          "focus:border-stroke focus:border-opacity-40", // Focus
          "disabled:opacity-30 disabled:cursor-not-allowed", // Disabled
          size === "sm" && "px-2 py-1.5",
          size === "md" && "p-3",
          className,
        ])}
        onFocus={onFocus}
        onBlur={handleBlur}
        maxLength={maxLength}
        minLength={minLength}
      />
    </div>
  );
});

export default Input;
