import React, { useState } from "react";
import ProductRecommendations from "./product_recommendations";
import News from "./news";
import { useNavigate } from "react-router-dom";
import Button from "../../base-components/Button";
import ContactUsModal from "./contact_us";

const SavingsSummary = () => {
  const totalSavings = 1000000;
  const savingsCount = 3;
  const totalInterest = 45000;
  const navigate = useNavigate();
  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <div className="bg-primary p-4 rounded-lg mb-6 grid gap-4 lg:flex justify-between items-center">
        <div>
          <p className="text-white">Миний нийт хуримтлал (MNT):</p>
          <p className="text-3xl font-bold text-white">
            {totalSavings.toLocaleString()}
          </p>
          <p className="text-white">
            Нийт хадгаламжийн тоо:{" "}
            <span className="font-semibold">{savingsCount}</span>
          </p>
          <p className="text-white">
            Нийт цугларсан хүү:{" "}
            <span className="text-green-500 font-semibold">
              {totalInterest.toLocaleString()}
            </span>
          </p>
        </div>
        <div>
          <button
            className="px-4 py-2 bg-white text-black rounded-full"
            onClick={() => {
              navigate("/my-savings", { replace: true });
            }}
          >
            Дэлгэрэнгүй
          </button>
        </div>
      </div>

      <ProductRecommendations />
      <News />
    </div>
  );
};

export default SavingsSummary;
