import { useEffect, useState } from "react";
import Modal from "../../../base-components/Modal";
import SavingsForm from "./modals/Form";
import SavingsInfo from "./modals/Info";
import Schedule from "./modals/Schedule";
import Contract from "./modals/Contract";
import RegisterForm from "./modals/RegisterInput";
import { useSelector } from "../../../redux/store";

const CreateSavings = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (step > 5) {
      onClose();
    }
  }, [step]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Хуримтлал үүсгэх"}
      withCloseButton={step === 1}
      withBackButton={step > 1}
      onBackClick={() => {
        setStep(step - 1);
      }}
      size={step === 5 ? "5xl" : "lg"}
    >
      {step === 1 && <SavingsInfo {...{ step, setStep }} />}
      {step === 2 && <RegisterForm {...{ step, setStep }} />}
      {step === 3 && <SavingsForm {...{ step, setStep }} />}
      {step === 4 && <Schedule {...{ step, setStep }} />}
      {step === 5 && <Contract {...{ step, setStep }} />}
    </Modal>
  );
};

export default CreateSavings;
