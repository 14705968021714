import React, { useState } from "react";
import { FaExchangeAlt, FaPlus, FaFileAlt } from "react-icons/fa"; // Import icons from react-icons
import ContactUs from "./contact_us";
import ContactUsModal from "./contact_us";
import CreateSavings from "./create-savings";

const savingsData = [
  {
    bank: "Хаан банк",
    amount: 500000,
    interestAccrued: 30000,
    interestRate: 5,
  },
  {
    bank: "Голомт банк",
    amount: 300000,
    interestAccrued: 20000,
    interestRate: 5,
  },
  {
    bank: "Хас банк",
    amount: 200000,
    interestAccrued: 10000,
    interestRate: 5,
  },
  {
    bank: "Хаан банк",
    amount: 500000,
    interestAccrued: 30000,
    interestRate: 5,
  },
];

const SavingsSection = () => {
  const [contectUseModalShow, setContactUsModal] = useState(false);

  return (
    <div className="">
      {/* Icon Button Row */}
      <div className="grid grid-cols-3 gap-2 xs:gap-4 mb-4">
        <button className="grid gap-1 place-items-center p-2 bg-white text-center rounded-md shadow">
          <FaExchangeAlt className="text-xl" />
          <span className="text-sm">Шилжүүлэх</span>
        </button>
        <button className="grid gap-1 place-items-center p-2 bg-white text-center rounded-md shadow">
          <FaPlus className="text-xl" />
          <span className="text-sm">Нэмэх</span>
        </button>
        <button
          onClick={() => {
            setContactUsModal(true);
          }}
          className="grid gap-1 place-items-center p-2 bg-white text-center rounded-md shadow"
        >
          <FaFileAlt className="text-xl" />
          <span className="text-sm">Хүсэлт</span>
        </button>
      </div>

      <h3 className="font-bold text-lg">Миний хуримтлал</h3>

      {/* Table Headers */}
      <div className="grid grid-cols-3 gap-2 mt-4">
        <span className="font-semibold">Хадгаламж</span>
        <span className="font-semibold">Орлого</span>
        <span className="font-semibold">Хуримтлагдсан хүү</span>
      </div>

      {/* Savings Data */}
      {savingsData.map((item, index) => (
        <div
          key={index}
          className="grid grid-cols-3 gap-2 mt-2 items-center border-b border-gray-400 pb-2"
        >
          <span>{item.bank}</span>
          <span>{item.amount.toLocaleString()}</span>
          <span>
            {item.interestAccrued.toLocaleString()}
            <span className="text-green-500 ml-2">+{item.interestRate}%</span>
          </span>
        </div>
      ))}
      {contectUseModalShow && (
        <ContactUsModal setContactUsModal={setContactUsModal}></ContactUsModal>
      )}
    </div>
  );
};

export default SavingsSection;
