import Service from "./BaseService";

const accessHistory = (config) => Service.get(`/api/v1/access/history`, { ...config, nonLoading: true }, 8080);
const registeredDevices = (config) => Service.get(`/api/v1/device/list`, { ...config, nonLoading: true }, 8080);

const infoDetail = (config) => Service.get(`/api/v1/info/detail`, { ...config, nonLoading: true }, 8080);


const ProfileService = {
    accessHistory,
    registeredDevices,
    infoDetail

}

export default ProfileService;