import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../../base-components/Dropdown";
import Button from "../../base-components/Button";
import { alphabet } from "../../utils/constants";
import Input from "../../base-components/Input";

const MongolianAlphabet = ({ onChangeLetter }) => {
  return (
    <div className="grid xs:grid-cols-7 grid-cols-6 gap-4">
      {alphabet.map((letter) => (
        <Button
          key={letter}
          variant="outlinePrimary"
          className="text-gray uppercase"
          onClick={() => onChangeLetter(letter)}
        >
          {letter}
        </Button>
      ))}
    </div>
  );
};

const RDInput = ({ onChange }) => {
  const [firstLetter, setFirstLetter] = useState();
  const [lastLetter, setLastLetter] = useState();
  const [number, setNumber] = useState();
  const firstLetterRef = useRef();
  const lastLetterRef = useRef();

  const handleChange = (e) => {
    // if (e.target.value.match(/[0-9]{8}/)) {
    setNumber(e.target.value);
    let returnValue = firstLetter + lastLetter + e.target.value;
    onChange(returnValue);
    // }
  };

  return (
    <div className="flex items-center space-x-2">
      <Dropdown
        ref={firstLetterRef}
        target={
          <Button variant="outlinePrimary" className="text-gray">
            {firstLetter ?? "Р"}
          </Button>
        }
        marginTop={0}
        dropdownClassName="rounded-2xl bg-white p-8 max-xs:w-full border-stroke border drop-shadow-lg"
      >
        <MongolianAlphabet
          onChangeLetter={(value) => {
            setFirstLetter(value);
            firstLetterRef.current?.close();
          }}
        />
      </Dropdown>
      <Dropdown
        ref={lastLetterRef}
        target={
          <Button variant="outlinePrimary" className="text-gray">
            {lastLetter ?? "Д"}
          </Button>
        }
        marginTop={0}
        dropdownClassName="rounded-2xl bg-white p-8 max-xs:w-full border-stroke border drop-shadow-lg"
      >
        <MongolianAlphabet
          onChangeLetter={(value) => {
            setLastLetter(value);
            lastLetterRef.current?.close();
          }}
        />
      </Dropdown>
      <Input
        type="number"
        size="md"
        value={number}
        placeholder="Дугаар"
        onChange={handleChange}
        maxLength={8}
      />
    </div>
  );
};

export default RDInput;
