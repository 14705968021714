import { useEffect } from "react";
import Home from "./home";
import { dispatch } from "../../redux/store";
import { getUserInfo } from "../../redux/slices/profile";

const Dashboard = () => {

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])
  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow pb-24 md:pb-0">
        <Home />
      </div>
    </div>
  );
};

export default Dashboard;
