import { useEffect } from "react";
import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import Card from "../../../../components/Card";
import { dispatch, useSelector } from "../../../../redux/store";
import { getScheduleThunk } from "../../../../redux/slices/product";
import { height } from "@mui/system";

const schedules = [
  { ognoo: "2024.12.01", dun: "200,000", huu: "10,000" },
  { ognoo: "2025.01.01", dun: "200,000", huu: "11,000" },
  { ognoo: "2025.02.01", dun: "200,000", huu: "12,000" },
];

const Schedule = ({ step, setStep }) => {
  const { schedule, scheduleLoading, product, selectedDuration, selectedAmount } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(
      getScheduleThunk({
        productId: product.productInfo.id,
        amountId: selectedAmount.id,
        durationId: selectedDuration.id,
      })
    );
  }, []);
  console.log("schedule, scheduleLoading", schedule, scheduleLoading);
  return (
    <>
      <Card className="mt-5 grid gap-5" style={{ height: 400, overflowY: 'auto' }}>
        <Typography>Хуримтлал нэмэх хуваарь</Typography>
        {scheduleLoading
          ? "Уншиж байна"
          : schedule.map((schedule) => (
            <div
              key={schedule.date}
              style={{ display: "flex", justifyContent: "space-between" }}
              className="bg-gray-200 rounded-lg p-3 grid gap-3"
            >
              <Typography>{schedule.date}</Typography>
              {/* <div className="grid grid-cols-2"> */}
              {/* <div> */}
              <Typography variant="Label" className="text-gray">

              </Typography>
              <Typography>{schedule.amount}₮</Typography>
              {/* </div> */}
              {/* <div>
                    <Typography variant="Label" className="text-gray">
                      Хуримтлагдах хүү
                    </Typography>
                    <Typography>{schedule.huu}₮</Typography>
                  </div> */}
            </div>
            // </div>
          ))}
      </Card>
      <div className="flex items-center justify-center mt-5">
        <Button onClick={() => setStep(step + 1)}>Үргэлжлүүлэх</Button>
      </div>
    </>
  );
};

export default Schedule;
