import { TbCircleCheck, TbDots } from "react-icons/tb";
import Typography from "../../base-components/Typography";
import Button from "../../base-components/Button";
import Card from "../../components/Card";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

const Savings = () => {
  const [step, setStep] = useState(1);

  const handleClick = () => {
    setStep(step + 1);
  };

  return (
    <div className="p-10  grid grid-cols-3 place-items-center gap-8">
      <Card className="bg-gray-200">
        <div className="grid gap-6">
          <div className="rounded-lg">
            <img
              src="/card.png"
              alt="card"
              className="rounded-lg h-40 w-full"
              className="rounded-lg h-40 w-full"
            />
            {/* <div className="bg-[#ecc4e6] rounded-lg p-10 flex items-center justify-center">
                <div
                  className="bg-gradient-to-tl from-[#f0d4ec] from-10% via-[#f9bddd] via-60% to-[#ffbac9] to-100% 
                                  p-10 w-full rounded-lg shadow-[4px_4px_10px_#edc4e6,-4px_-4px_10px_#edc4e6]"
                ></div>
              </div> */}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <img
                src="/card.png"
                alt="card"
                className="rounded-lg h-12 w-16"
              />
              <Typography className="text-font">Subtitle</Typography>
            </div>
            <TbDots />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Button>Мөнгө нэмэх</Button>
            <Button>Хуулга</Button>
          </div>
          <div className="bg-primary p-4 rounded-lg grid gap-3">
            <div className="flex items-center justify-between">
              <Typography variant="Label" className="text-white">
                Цуглуулсан дүн:
              </Typography>
              <Typography variant="Label" className="text-white">
                100,000
              </Typography>
            </div>
            <div className="flex items-center justify-between">
              <Typography variant="Label" className="text-white">
                Хуримтлагдсан дүн:
              </Typography>
              <Typography variant="Label" className="text-white">
                0.0
              </Typography>
            </div>
            <div className="flex items-center justify-between">
              <Typography variant="Label" className="text-white">
                Хүү /жил/:
              </Typography>
              <Typography variant="Label" className="text-white">
                18%
              </Typography>
            </div>
            <div className="flex items-center justify-between">
              <Typography variant="Label" className="text-white">
                Сар бүрийн:
              </Typography>
              <Typography variant="Label" className="text-white">
                20
              </Typography>
            </div>
          </div>
          <div className="rounded-full h-2 bg-gray-300 cursor-pointer w-full">
            <div
              style={{ width: "20%" }}
              className="rounded-full h-2 bg-primary"
            ></div>
          </div>
          <div className="flex items-center justify-between">
            <Typography variant="Label" className="text-font">
              10 жил үлдсэн
            </Typography>
            <Typography variant="Label" className="text-font">
              2034-12-20
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Savings;
