import React, { useEffect, } from "react";
import { dispatch, useSelector } from "../../redux/store";
import { changeDanSuccess, danConnectThunk } from "../../redux/slices/profile";
import { useNavigate } from "react-router-dom";
import { getProductDetail, openProductModal } from "../../redux/slices/product";

const DanResponse = () => {
    const { danLoading, danSuccess, error } = useSelector(
        (state) => state.profile
    );

    const navigate = useNavigate();
    useEffect(() => {
        dispatch(danConnectThunk())
    }, [])


    useEffect(() => {
        console.log("iishee orjiinuu", danSuccess)
        if (danSuccess) {
            const urlParams = new URLSearchParams(window.location.search);
            const state = urlParams.get('state');


            dispatch(getProductDetail(state));
            navigate(`/home`);
            dispatch(changeDanSuccess())
        }
    }, [danSuccess]);
    console.log("iishee orjiinuu error", error)

    if (danLoading) return <>Уншиж байна...</>
    return (
        danSuccess ? <div className="max-w-4xl mx-auto mt-5">
            Амжилттай
        </div > :
            <div className="max-w-4xl mx-auto mt-5">
                Уучлаарай дахин оролдоно уу
                <div>{error}</div>
            </div >
    );
};

export default DanResponse;
