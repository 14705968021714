import { createSlice } from "@reduxjs/toolkit";
// utils
import { dispatch } from "../store";

//services
import ProductService from "../../services/ProductService";
// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  success: false,
  products: [],
  product: null,
  productLoading: false,
  product: null,
  modalOpen: false,
  schedule: [],
  scheduleLoading: false,
  scheduleError: null,
  selectedAmount: null,
  selectedDuration: null,
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    startDetailLoading(state) {
      state.productLoading = true;
    },
    stopDetailLoading(state) {
      state.productLoading = false;
    },
    startScheduleLoading(state) {
      state.scheduleLoading = true;
    },
    stopScheduleLoading(state) {
      state.scheduleLoading = false;
    },
    scheduleSuccess(state, action) {
      state.scheduleLoading = false;
      state.schedule = action.payload;
    },
    setSelectedAmount(state, action) {
      state.selectedAmount = action.payload;
    },
    setSelectedDuration(state, action) {
      state.selectedDuration = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.productLoading = false;
      console.log("error ni yu ireed bna", action);
      state.error = action.payload;
    },
    initialStateProduct(state) {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.products = [];
    },

    getProductDataSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.products = action.payload;
    },
    getProductDetailSuccess(state, action) {
      state.product = action.payload;
      state.productLoading = false;
      state.error = null;
      state.success = false;
      state.modalOpen = true;
      state.selectedAmount = action.payload.amount[0]
      state.selectedDuration = action.payload.duration[0]
    },
    hideError(state) {
      state.error = null;
      state.otpResendSuccess = false;
    },

    closeProductModal(state) {
      state.modalOpen = false;
      state.product = null;
    },
    productDetailSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.product = action.payload;
      state.modalOpen = true;
    },
    productDetailError(state, action) {
      state.loading = false;
      state.productLoading = false;
      state.modalOpen = false;
      state.error = action.payload;
    },
    scheduleDetailError(state, action) {
      state.scheduleLoading = false;
      state.schedule = [];
      state.scheduleError = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openProductModal,
  closeProductModal,
  startLoading,
  stopLoading,
  hideError,
  hasError,
  initialStateProduct,
  setSelectedAmount,
  setSelectedDuration
} = slice.actions;

// // ----------------------------------------------------------------------

export function getProductList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ProductService.getProductList();
      console.log("getProductsList", response);
      dispatch(slice.actions.getProductDataSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.hasError(error));
      // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
    }
  };
}

export function getProductDetail(id) {
  return async () => {
    dispatch(slice.actions.startDetailLoading());
    try {
      const response = await ProductService.getProductDetail(id);
      console.log("getProductDetail", response);
      dispatch(slice.actions.getProductDetailSuccess(response?.responseData));
    } catch (error) {
      dispatch(slice.actions.productDetailError(error));
    }
  };
}

export function getScheduleThunk(body) {
  return async () => {
    dispatch(slice.actions.startScheduleLoading());
    try {
      const response = await ProductService.getSchedule(body);
      console.log("getProductDetail", response);
      dispatch(slice.actions.scheduleSuccess(response?.responseData));
    } catch (error) {
      dispatch(slice.actions.scheduleDetailError(error));
    }
  };
}
