import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import Card from "../../../../components/Card";
import {
  changeDanCheckSuccess,
  changeDanSuccess,
  danCheckThunk,
} from "../../../../redux/slices/profile";
import { dispatch, useSelector } from "../../../../redux/store";
import AlertModal from "../../../../base-components/AlertModal";
import htmlReactParser from 'html-react-parser';

const SavingsInfo = ({ step, setStep }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [htmlContent, setHtmlContent] = useState("<h1>Hello, World!</h1>");

  const handleChange = (newValue) => {
    setHtmlContent(newValue);
  };
  //product-n мэдээлэл авахдаа ингэнэ
  const { product } = useSelector((state) => state.product);
  const { danCheckSuccess, danConnected } = useSelector(
    (state) => state.profile
  );

  const handleClick = () => {
    // alert("Энэ дээр нэг диалог харуулах хэрэгтэй байгаа");
    // setStep(step + 1);
    dispatch(danCheckThunk());
  };
  useEffect(() => {
    console.log("iishee orjiinuu", danCheckSuccess);
    if (danCheckSuccess) {
      // if (danConnected) {
      //   setStep(step + 2);
      // } else {
      //   setOpenDialog(true);
      // }
      setOpenDialog(true);
      dispatch(changeDanCheckSuccess());
    }
  }, [danCheckSuccess, danConnected]);

  return (
    <>
      <div className="grid gap-5 mt-5">
        <Card className="h-72 max-h-90 overflow-auto">
          <div className="grid gap-4">
            <Typography className="text-primary">
              {product?.productInfo?.name}
            </Typography>

            {htmlReactParser(product?.productInfo?.description ?? htmlContent)}
          </div>
        </Card>
        <div className="flex justify-center mb-5">
          <Button disabled={step > 1} onClick={handleClick}>
            Хуримтлал нээх
          </Button>
        </div>
      </div>

      <AlertModal
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        type="info"
        title="Мэдээлэл"
        closeButton={true}
        message="Баталгаажуулахын тулд танилт нэвтрэлтийн дан систем рүү шилжүүллээ"
        footer={
          <div className="flex items-center space-x-4">
            <Button
              variant="outlinePrimary"
              onClick={() => setOpenDialog(false)}
            >
              Цуцлах
            </Button>
            <Button onClick={() => {
              setStep(step + 1)
            }}>Тийм</Button>
          </div>
        }
        placement="top"
      // size,
      />
    </>
  );
};

export default SavingsInfo;
